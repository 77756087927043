<template>
  <div class="transition">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="transition-back"
      @click="back()"
    />
    <div class="transition-text flex" @click="regulationShow = !regulationShow">
      <img
        src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10555.png"
        alt=""
        class="transition-text-img"
      />
      <div class="transition-text-text">规则介绍</div>
    </div>

    <div class="transition-content flex">
      <div class="transition-content-box" v-if="typebac == 0 || typebac == 4">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10803.png"
          alt=""
          class="transition-content-box-title"
        />
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/activity/20241022-1700180.gif"
          alt=""
          class="transition-content-box-bgc"
        />
        <div class="transition-content-box-numBgc">
          <div class="flex" style="justify-content: space-between">
            <div class="flex transition-content-box-numBgc-left">
              <div>每日释放</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/123333333333.png"
                mode=""
                class="transition-content-box-numBgc-left-img"
              />
              <div
                class="transition-content-box-numBgc-left-num"
                v-if="dailyOutput[0]"
              >
                {{ dailyOutput[0][0].outNum }}
              </div>
            </div>
            <div class="flex transition-content-box-numBgc-left">
              <div>每日释放</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/crystalintegration.png"
                mode=""
                class="transition-content-box-numBgc-left-img1"
              />
              <div
                class="transition-content-box-numBgc-left-num"
                v-if="dailyOutput[0]"
              >
                {{ dailyOutput[0][1].outNum }}
              </div>
            </div>
          </div>
          <div class="transition-content-box-numBgc-but">
            <div
              class="transition-content-box-numBgc-but-transition"
              @click="doPledge(0)"
            >
              转换
            </div>
            <div class="flex transition-content-box-numBgc-but-consume">
              <div>消耗</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/123333333333.png"
                mode=""
                class="transition-content-box-numBgc-but-consume-img"
              />
              <div
                class="transition-content-box-numBgc-but-consume-num"
                v-if="activityData[0]"
              >
                x{{ activityData[0].putIn }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="transition-content-box margin"
        v-if="typebac == 1 || typebac == 4"
      >
        <div
          class="transition-content-box-number flex"
          @click="subordinateDataButton(0)"
        >
          <el-progress
            :percentage="
              subordinateData.length >= 5 ? 100 : subordinateData.length * 20
            "
            stroke-width="61px"
            color="#E953D0"
            define-back-color="#5F4E81"
            :show-text="false"
          ></el-progress>
          <div class="transition-content-box-number-num">
            {{ subordinateData.length > 5 ? 5 : subordinateData.length }}/5
          </div>
        </div>
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10807.png"
          alt=""
          class="transition-content-box-title"
        />
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/activity/20241022-1700181.gif"
          alt=""
          class="transition-content-box-bgc"
        />
        <div class="transition-content-box-numBgc numBgc1">
          <div class="flex" style="justify-content: space-between">
            <div class="flex transition-content-box-numBgc-left">
              <div>每日释放</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/123333333333.png"
                mode=""
                class="transition-content-box-numBgc-left-img"
              />
              <div
                class="transition-content-box-numBgc-left-num"
                v-if="dailyOutput[1]"
              >
                {{ dailyOutput[1][0].outNum }}
              </div>
            </div>
            <div class="flex transition-content-box-numBgc-left">
              <div>每日释放</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/crystalintegration.png"
                mode=""
                class="transition-content-box-numBgc-left-img1"
              />
              <div
                class="transition-content-box-numBgc-left-num"
                v-if="dailyOutput[1]"
              >
                {{ dailyOutput[1][1].outNum }}
              </div>
            </div>
          </div>
          <div class="transition-content-box-numBgc-but">
            <div
              class="transition-content-box-numBgc-but-transition"
              @click="doPledge(1)"
            >
              转换
            </div>
            <div class="flex transition-content-box-numBgc-but-consume">
              <div>消耗</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/123333333333.png"
                mode=""
                class="transition-content-box-numBgc-but-consume-img"
              />
              <div
                class="transition-content-box-numBgc-but-consume-num"
                v-if="activityData[1]"
              >
                x{{ activityData[1].putIn }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="transition-content-box" v-if="typebac == 2 || typebac == 4">
        <div
          class="transition-content-box-number flex"
          @click="subordinateDataButton(1)"
        >
          <el-progress
            :percentage="
              subordinateData2.length >= 3
                ? 100
                : subordinateData2.length * 30.33
            "
            stroke-width="61px"
            color="#E953D0"
            define-back-color="#5F4E81"
            :show-text="false"
          ></el-progress>
          <div class="transition-content-box-number-num">
            {{ subordinateData2.length > 3 ? 3 : subordinateData2.length }}/3
          </div>
        </div>
        <div
          class="transition-content-box-number number1 flex"
          @click="subordinateDataButton(0)"
        >
          <el-progress
            :percentage="
              subordinateData.length >= 30 ? 100 : subordinateData.length * 3.33
            "
            stroke-width="61px"
            color="#E953D0"
            define-back-color="#5F4E81"
            :show-text="false"
          ></el-progress>
          <div class="transition-content-box-number-num">
            {{ subordinateData.length > 30 ? 30 : subordinateData.length }}/30
          </div>
        </div>
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10813.png"
          alt=""
          class="transition-content-box-title"
        />
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/activity/20241022-1700182.gif"
          alt=""
          class="transition-content-box-bgc"
        />
        <div class="transition-content-box-numBgc numBgc2">
          <div class="flex" style="justify-content: space-between">
            <div class="flex transition-content-box-numBgc-left">
              <div>每日释放</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/123333333333.png"
                mode=""
                class="transition-content-box-numBgc-left-img"
              />
              <div
                class="transition-content-box-numBgc-left-num"
                v-if="dailyOutput[2]"
              >
                {{ dailyOutput[2][0].outNum }}
              </div>
            </div>
            <div class="flex transition-content-box-numBgc-left">
              <div>每日释放</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/crystalintegration.png"
                mode=""
                class="transition-content-box-numBgc-left-img1"
              />
              <div
                class="transition-content-box-numBgc-left-num"
                v-if="dailyOutput[2]"
              >
                {{ dailyOutput[2][1].outNum }}
              </div>
            </div>
          </div>
          <div class="transition-content-box-numBgc-but">
            <div
              class="transition-content-box-numBgc-but-transition"
              @click="doPledge(2)"
            >
              转换
            </div>
            <div class="flex transition-content-box-numBgc-but-consume">
              <div>消耗</div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/activity/123333333333.png"
                mode=""
                class="transition-content-box-numBgc-but-consume-img"
              />
              <div
                class="transition-content-box-numBgc-but-consume-num"
                v-if="activityData[2]"
              >
                x{{ activityData[2].putIn }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="show"
      width="40%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="addition">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/marketplace/X2x.png"
          mode=""
          class="addition-img"
          @click="handleClose()"
        />
        <div class="flex" style="justify-content: space-between">
          <div>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/31231231.png"
              mode=""
              class="addition-bacw"
            />
            <div class="addition-num" v-if="activityData[type]">
              ×{{ activityData[type].putIn ** 1 * (value / 10) }}
            </div>
          </div>
          <div class="addition-ccc addition-ccc1">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/434324.png"
              mode=""
              class="addition-bacw"
            />
            <div class="addition-num" v-if="activityData[type]">
              ×{{ transition }}
            </div>
          </div>
          <div class="addition-ccc">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/activity/31231231.png"
              mode=""
              class="addition-bacw"
            />
            <div class="addition-num" v-if="activityData[type]">
              ×{{ transition1 }}
            </div>
          </div>
        </div>
        <div class="addition-progressbar flex">
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/home/add.png"
            mode=""
            class="addition-progressbar-img right19"
            style=""
            @click="subtract"
          />
          <el-slider
            v-model="value"
            :show-tooltip="false"
            height="7"
            :step="10"
            :min="10"
            :max="100"
          ></el-slider>
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/home/reduce.png"
            mode=""
            class="addition-progressbar-img left14"
            style=""
            @click="addnum"
          />
        </div>
        <div class="addition-button" @click="putinto">投入</div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="putintoShow"
      width="40%"
      :show-close="false"
    >
      <div class="subordinates">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/mine/notyetClose.png"
          mode=""
          class="subordinates-close"
          @click="putintoShow = !putintoShow"
        />
        <div class="subordinates-title">
          开通{{ subordinateDataType == 0 ? "初级" : "中级" }}实验室UID
        </div>
        <div class="subordinates-ul">
          <div
            v-for="(item, index) in subordinateDataType == 0
              ? subordinateData
              : subordinateData2"
            :key="index"
            class="subordinates-ul-li"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="regulationShow"
      width="60%"
      :show-close="false"
    >
      <div class="regulationShowsBgc">
        <div class="regulationShowsBgc-title">
          <img
            src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
            alt=""
            class="regulationShowsBgc-x"
            @click="regulationShow = false"
          />规则介绍
        </div>
        <div class="regulationShowsBgc-text">
          <div>初级实验室</div>
          <div>投入1000-10000芯片积分（22%）</div>
          <div>举例：投入1000 每天释放33.3芯片积分和7.3晶体积分。</div>
          <div>从投入的那天算起，连续释放30天。</div>
          <div class="regulationShowsBgc-text-top">中级实验室</div>
          <div>投入10000-100000（25%）</div>
          <div>
            举例：投入10000芯片积分，每天释放333芯片积分和83.3晶体积分。
          </div>
          <div>从投入的那天算起，连续释放30天。</div>
          <div class="regulationShowsBgc-text-top">高级实验室</div>
          <div>投入100000-1000000（30%）</div>
          <div>
            举例：投入100000芯片积分，每天释放3333芯片积分和1000晶体积分。
          </div>
          <div>从投入的那天算起，连续释放30天。</div>
          <div class="regulationShowsBgc-text-top">邀请奖励：</div>
          <!-- <div>
            1.一个uid同一种实验室只允许存在一个。释放完之后可以接着开通。
          </div>
          <div>2.开通初级才能激活中级，开通中级才能激活高级。</div>
          <div>3.开通中级实验室需邀请5个用户开通初级实验室。</div>
          <div>4.开通高级实验室需邀请3个中级实验室或30个初级实验室。</div> -->
          <div>
            1.邀请好友开通实验室，根据好友开通的等级。获得好友开通等级每天释放的晶体积分的10%的奖励（比如邀请好友开通了初级实验室，每天获得7.3晶体积分的10%）
          </div>
          <div>
            2.邀请盟友（被邀请好友的下级）开通实验室，根据盟友开通的等级，获得盟友每天晶体积分的5%奖励。
          </div>
          <div>
            3.举例:A玩家邀请B玩家开通中级实验室，B玩家邀请C玩家开通高级实验室。A玩家获得B玩家晶体产出的10%，获得C玩家晶体产出的5%。B玩家获得C玩家晶体产出的10%。
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      putintoShow: false,
      showghez: false,
      activityData: [],
      type: null,
      value: 10,
      typebac: null,
      dailyOutput: [],
      subordinateData: [],
      subordinateData2: [],
      subordinateDataType: 0,
      regulationShow: false,
    };
  },
  computed: {
    transition() {
      let number = null;
      if (this.type == 0) {
        number = 7.3 * 30 * (this.value / 10);
        return Math.round(number * 100) / 100;
      } else if (this.type == 1) {
        number = 83.3 * 30 * (this.value / 10);
        return Math.round(number * 100) / 100;
      } else {
        number = 1000 * 30 * (this.value / 10);
        return Math.round(number * 100) / 100;
      }
    },
    transition1() {
      let number = null;
      if (this.type == 0) {
        33;
        number = 33.3 * 30 * (this.value / 10);
        return Math.round(number * 100) / 100;
      } else if (this.type == 1) {
        number = 333 * 30 * (this.value / 10);
        return Math.round(number * 100) / 100;
      } else {
        number = 3333 * 30 * (this.value / 10);
        return Math.round(number * 100) / 100;
      }
    },
  },
  mounted() {
    this.typebac = this.$route.query.type;
    this.listAllPledge();
    this.everyDayIncome();
  },
  methods: {
    back() {
      history.back();
    },
    //查看下级
    subordinateDataButton(type) {
      this.subordinateDataType = type;
      this.putintoShow = true;
    },
    subtract() {
      if (this.value == 10) {
        this.value = 10;
      } else {
        this.value -= 10;
      }
    },
    addnum() {
      if (this.value == 100) {
        this.value = 100;
      } else {
        this.value += 10;
      }
    },

    handleClose() {
      this.show = false;
      this.value = 10;
    },
    close() {
      this.show = false;
      this.value = 10;
    },
    //投入
    async putinto() {
      const params = {
        pledgeId: this.activityData[this.type].id,
        num: this.value / 10,
      };
      const { data, code } = await this.$ajax.doPledge(params);
      this.close();
      this.listAllPledge();
    },
    doPledge(type) {
      this.type = type;
      this.show = true;
      console.log(this.show);
    },
    // 查看每日释放
    async everyDayIncome() {
      const { data, code } = await this.$ajax.everyDayIncome();
      this.dailyOutput = data;
    },
    //查询
    async listAllPledge() {
      const { data, code } = await this.$ajax.listAllPledge();
      this.activityData = data;
      this.activityData.forEach((item, index) => {
        this.mySubordinatesEnergyConversionMachineNum(item.id, index);
      });
    },
    //查询下级的开通状态
    async mySubordinatesEnergyConversionMachineNum(pledgeId, index) {
      const params = {
        pledgeId: pledgeId,
      };
      const { data, code } =
        await this.$ajax.mySubordinatesEnergyConversionMachineNum(params);
      if (index == 0) {
        if (data) {
          this.subordinateData = data;
        }
      }
      if (index == 1) {
        if (data) {
          this.subordinateData2 = data;
        }
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.transition {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/activity/bgc.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 150px;
  padding-bottom: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-text:hover {
    cursor: pointer;
  }
  &-text {
    position: absolute;
    top: 179px;
    left: 259px;
    &-img {
      width: 29px;
      height: 29px;
    }
    &-text {
      margin-left: 8.25px;
      color: #f1eae2;
      font-size: 22px;
    }
  }
  &-content {
    .margin {
      margin: 0 30px;
    }
    &-box {
      width: 511px;
      height: 451px;
      background: url("https://cdn.bubbleplan.cn/static/activity/Rectangle34626300.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding-top: 6px;
      .number1 {
        width: 165px;
        height: 39.83px;
        background: url("https://cdn.bubbleplan.cn/static/activity/Group10808.png")
          no-repeat;
        background-size: 100% 100%;
        top: 111px !important;
      }
      &-number:hover {
        cursor: pointer;
      }
      &-number {
        position: absolute;
        top: 68px;
        right: -16px;
        width: 148.78px;
        height: 39.83px;
        background: url("https://cdn.bubbleplan.cn/static/activity/Group10808.png")
          no-repeat;
        background-size: 100% 100%;
        z-index: 121;
        align-items: center;
        padding: 0 4px;
        justify-content: center;
        padding-top: 6px;

        :deep(.el-progress) {
          width: 81px;
        }
        &-num {
          margin-left: 11.3px;
          color: #ff9029;
          font-family: "TsangerYuMo1";
          font-size: 18px;
          text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000,
            -2px 2px 0 #000000, 2px 2px 0 #000000;
          /* 模拟描边效果 */
        }
      }

      &-title {
        width: 472px;
        height: 58px;
        margin: auto;
        display: block;
        z-index: 9;
        position: relative;
      }
      &-bgc {
        width: 504px;
        height: 219px;
        margin: auto;
        display: block;
        margin-top: -16px;
      }
      .numBgc1 {
        width: 528px;
        height: 182px;
        background: url("https://cdn.bubbleplan.cn/m_bubble/activity/Group10812.png")
          no-repeat !important;
        background-size: 100% 100% !important;
      }
      .numBgc2 {
        width: 528px;
        height: 182px;
        background: url("https://cdn.bubbleplan.cn/m_bubble/activity/Group10814.png")
          no-repeat !important;
        background-size: 100% 100% !important;
      }
      &-numBgc {
        width: 528px;
        height: 182px;
        background: url("https://cdn.bubbleplan.cn/static/activity/Group10805.png")
          no-repeat;
        background-size: 100% 100%;
        margin-top: -25px;
        margin-left: -8px;
        z-index: 9;
        position: relative;
        padding: 70px 47px 0 52px;
        &-left {
          color: #dadada;
          font-size: 19px;
          align-items: center;
          &-img {
            margin-left: 17.43px;
            width: 43px;
            height: 36px;
          }
          &-num {
            font-size: 22px;
            font-weight: bold;
            margin-left: 6px;
          }
          &-img1 {
            margin-left: 17.43px;
            width: 28px;
            height: 35px;
          }
        }
        &-but {
          width: 276.52px;
          height: 96.18px;
          background: url("https://cdn.bubbleplan.cn/m_bubble/activity/Group10806.png")
            no-repeat;
          background-size: 100% 100%;
          margin: auto;
          margin-top: 20px;
          padding-top: 9px;
          &-transition:hover {
            cursor: pointer;
          }
          &-transition {
            color: #ffffff;
            font-family: "TsangerYuMo1";
            font-size: 30px;
            text-align: center;
          }
          &-consume {
            margin-top: 7px;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            &-img {
              width: 42px;
              height: 34px;
              margin-left: 5px;
            }
            &-num {
              color: #ffffff;
              font-family: "TsangerYuMo1";
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  :deep(.el-dialog) {
    background: transparent;
  }
  .addition {
    width: 689px;
    height: 470px;
    background: url("https://cdn.bubbleplan.cn/static/activity/Group10580.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 0 60px;
    padding-top: 97px;
    text-align: center;
    align-items: center;
    .right19 {
      margin-right: 19px;
    }
    .left14 {
      margin-left: 14px;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 67px;
      height: 66px;
      position: absolute;
      right: -22px;
      top: -20px;
    }

    &-bacw {
      margin: auto;
      width: 158px;
      height: 158px;
    }
    .addition-ccc1 {
      margin-left: 80px;
    }
    .addition-ccc {
      margin-right: 7px;
    }

    &-num {
      color: #ffffff;
      font-family: "TsangerYuMo";
      font-size: 32px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      margin-top: -40px;
    }

    &-progressbar {
      margin-top: 66px;
      align-items: center;
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 43px;
        height: 47px;
      }
      :deep(.el-slider) {
        width: 100%;
      }
      :deep(.el-slider__bar) {
        background-color: #fff;
      }
      :deep(.el-slider__button) {
        width: 33px !important;
        height: 49px !important;
        background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector740.png")
          no-repeat;
        background-size: 100% 100%;
        background-color: transparent !important;
        margin-top: -6px !important;
        border: none !important;
      }
    }
    &-button:hover {
      cursor: pointer;
    }
    &-button {
      width: 194px;
      height: 80px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector594.png")
        no-repeat;
      background-size: 100% 100%;
      margin: auto;
      margin-top: 30px;
      color: #ffffff;
      text-align: center;
      line-height: 80px;
      font-family: "TsangerYuMo";
      font-size: 30px;
      font-weight: bold;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
    }
  }
  :deep(.el-dialog) {
    margin-top: 10vh !important;
  }
  .subordinates {
    width: 467.91px;
    height: 590.11px;
    background: url("https://cdn.bubbleplan.cn/static/activity/Group10835.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 29px 53px;
    &-close:hover {
      cursor: pointer;
    }
    &-close {
      width: 45.17px;
      height: 44.44px;
      position: absolute;
      right: -14px;
      top: -10px;
    }
    &-title {
      text-align: center;
      color: #fbeeff;
      font-size: 20px;
      text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
        2px 2px 0 #000000;
      /* 模拟描边效果 */
      font-weight: Bold;
    }
    &-ul {
      width: 100%;
      flex: 1;
      margin-top: 28px;
      overflow: auto;
      &-li {
        width: 362px;
        height: 50px;
        border-radius: 7.89px;
        background: #666690;
        box-shadow: 0 2.87px 2.87px 0 #00000040 inset;
        margin-bottom: 7px;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .regulationShowsBgc {
    width: 1186px;
    height: 745px;
    background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
      no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-top: 94px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 78px 124px 103.34px 168px;
    &-x {
      cursor: pointer;
    }
    &-x {
      position: absolute;
      top: 63.96px;
      right: 36.76px;
      width: 80.88px;
      height: 79.56px;
    }
    &-title {
      width: 100%;
      color: #574352;
      font-size: 48px;
      font-weight: 900;
      text-align: center;
    }
    &-text::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-text {
      flex: 1;
      overflow: auto;
      margin-top: 40px;
      color: #574352;
      font-size: 24px;
      line-height: 40.8px;
      // background: #d6c4ba;
      padding: 45px 50px;
      &-top {
        margin-top: 30px;
      }
    }
  }
}
</style>
